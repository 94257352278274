<template>
  <div class="">
    <div class="flex">
      <Title text="方案購買紀錄"></Title>
      <el-button type="primary" size="default" @click="fetcher"
        >重整資料</el-button
      >
    </div>
    <div class="bg-white rounded shadow p-4 mt-8">
      <ElTable :data="rows" :loading="loading" :empty-text="'目前沒有資料'">
        <el-table-column prop="orderId" label="訂單號碼" sortable>
        </el-table-column>
        <el-table-column prop="title" label="方案名稱" sortable />
        <el-table-column prop="subject" label="科目" sortable>
        </el-table-column>
        <el-table-column prop="date" label="日期" sortable width="150">
        </el-table-column>
        <el-table-column prop="price" label="金額" sortable width="100">
        </el-table-column>
        <el-table-column prop="status" label="狀態" sortable width="100">
          <template #default="scope">
            <div
              class="inline-block rounded-lg text-white text-xs py-2 px-2"
              :class="scope.row.status == 0 ? 'bg-danger' : 'bg-green-500'"
              v-text="status[scope.row.status]"
            ></div>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template #default="scope">
            <el-button size="default" type="text" @click="onDetail(scope.row)"
              >查看明細</el-button
            >
          </template>
        </el-table-column>
      </ElTable>
    </div>
    <el-dialog
      v-model="dialog.state.show"
      custom-class="md:w-[94%] w-[800px]"
      :destroy-on-close="true"
    >
      發票{{ invoice }}
    </el-dialog>
  </div>
</template>

<script setup>
import Payment from '@/api/Payment.js';
import Title from '@/components/Titles.vue';

import { ElTable, ElTableColumn, ElDialog } from 'element-plus';
import useDialog from '@/hook/useDialog';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();
const status = {
  0: '交易失敗',
  '-1': '交易處理中',
  1: '交易已完成',
};

const dialog = useDialog();
const rows = ref([]);
const loading = ref(false);
const fetcher = () => {
  loading.value = true;
  rows.value = [];
  Payment.historyPay().then((res) => {
    if (res.code == 1) {
      rows.value = res.data.rows;
    }

    loading.value = false;
  });
};
fetcher();
const invoice = ref({
  id: 0,
});
const onDetail = (row) => {
  let data;
  if (row.type === 'auth') {
    data = {
      name: 'Checkout.Waiting',
      query: { orderId: row.orderId },
    };
  } else if (row.type === 'nonauth') {
    data = {
      name: 'Qb.Waiting',
      query: { orderId: row.orderId },
    };
  } else {
    return;
  }
  router.push({
    ...data,
  });
};
const openInvoice = (id) => {
  dialog.toggle({ show: true });
  invoice.value.id = id;
};
</script>

<style lang="sass" scoped>
:deep(.el-table)
  @screen md
    colgroup
      display: none
    thead
      display: none
    tr
      border-bottom: 1px solid #eee

    td
      display: block

      &:not(:last-child)
        border-bottom: 0
</style>
